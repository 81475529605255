import { Answer } from "../types/questTypes";

export const answerData: Answer[] = [
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "content1",
    index: "answer1",
    answerArray: ["ヒラメキ"],
    questHintMVArray: [
      {
        kanji:
          "四種類の時計の名前をカタカナにして●に埋めよう。一番下は「日時計」だよ。数字の傾きにも注意。",
        furigana: "",
      },
    ],
    explanation: "",
  },
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "content2",
    index: "answer2",
    answerArray: ["1123"],
    questHintMVArray: [
      {
        kanji:
          "そのままでは読めないようだ。デジタル数字の0や4、8の形を考えてみよう。真ん中の横線1本だけ残っているのが0だよ。",
        furigana: "",
      },
    ],
    explanation: "",
  },
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "content3",
    index: "answer3",
    answerArray: ["バレエ"],
    questHintMVArray: [
      {
        kanji:
          "最初の3問は、間違えても何度でも答えられるよ。左と右、どちらを選んだかを覚えておこう。",
        furigana: "",
      },
    ],
    explanation: "",
  },
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "content4",
    index: "answer4",
    answerArray: ["大志"],
    questHintMVArray: [
      {
        kanji: "答えは漢字2文字。画像をぐりぐり動かして中心から読んでみよう。",
        furigana: "",
      },
    ],
    explanation: "",
  },
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "content5",
    index: "answer5",
    answerArray: ["ケガニ"],
    questHintMVArray: [
      {
        kanji: "カタカナ3文字が隠れているよ。1文字目は「ケ」。",
        furigana: "",
      },
    ],
    explanation: "",
  },
  // {
  //   ver: "v2",
  //   orgId: "tom-sawyer",
  //   course: "odori-sample",
  //   room: "room1",
  //   content: "content6",
  //   index: "answer6",
  //   answerArray: ["れきし"],
  //   questHintMVArray: [
  //     {
  //       kanji:
  //         "もみじの落ち葉がつくと「か」が「き」に、「は」が「ひ」になっているね。何か法則がありそうだ。他の落ち葉にもそれぞれ法則があるよ。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation: "",
  // },
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    content: "final",
    index: "answer7",
    answerArray: ["はなたば"],
    questHintMVArray: [
      {
        kanji:
          "アイテムを確認しよう。書かれているスポット名の間にはそれぞれ2文字ずつあるね。",
        furigana: "",
      },
      {
        kanji:
          "1〜8まで、当てはまる文字を順に読もう。1は「こ」、8は「ば」だよ。",
        furigana: "",
      },
    ],
    explanation: "",
  },
];
