import { memo, useCallback, useEffect, useState } from "react";
import { ReactNode } from "react";
import { useSettingState } from "../common/context/settingState.context";
import { isSettingState } from "../common/types/typeGuard";
import styles from "./MainFlameTemplate.module.scss";

interface Props {
  children: ReactNode;
}

const MainFlameTemplate: React.FC<Props> = ({ children }) => {
  const { settingState } = useSettingState();

  if (!isSettingState(settingState)) return null;

  const { withDocumentMode } = settingState;

  return (
    <>
      {!withDocumentMode && <MainMode>{children}</MainMode>}
      {withDocumentMode && <DocumentMode>{children}</DocumentMode>}
    </>
  );
};

export default memo(MainFlameTemplate);

// …………………………………………………………………………………………………………………………………………………………………………………………………………

const MainMode: React.FC<Props> = ({ children }) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [innerHeight, setInnerHeight] = useState<number>();

  const handleWindowResize = useCallback(() => {
    const innerH = window.innerHeight;
    const innerW = window.innerWidth;
    const baseRatio = 553 / 375;
    const nowRetio = innerH / innerW;
    setInnerHeight(innerH);
    if (baseRatio <= nowRetio) {
      setWidth(innerW);
      setHeight(innerW * baseRatio);
    } else {
      setWidth((375 / 553) * innerH);
      setHeight(innerH);
    }
  }, []);

  useEffect(() => {
    if (!width && !height) {
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);
    }
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize, height, width]);

  return (
    <div
      style={{
        height: innerHeight || "100%",
      }}
      className={styles.mainBody}
    >
      <div
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        className={styles.displayWrap}
      >
        {children}
      </div>
    </div>
  );
};

// …………………………………………………………………………………………………………………………………………………………………………………………………………

// [ ] 資料用にpaddingなど調整

const DocumentMode: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.documentBody}>
      <div className={styles.documentArea}>
        <div className={styles.displayWrap}>
          <div className={styles.display}>{children}</div>
        </div>
        <div className={styles.documentWrap}>
          <p>書類</p>
        </div>
      </div>
    </div>
  );
};
