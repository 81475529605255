import { GoogleAuthProvider, linkWithPopup } from "firebase/auth";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { IoLogoGoogle } from "react-icons/io5";
import { RiLockPasswordFill } from "react-icons/ri";
import { useSetRecoilState } from "recoil";
import { divTexts } from "../../../../common/const/divTexts";
import { useUserState } from "../../../../common/context/userState.context";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { auth } from "../../../../common/lib/firebase";
import { DisplayMenuState } from "../../../../common/recoil/menuAtoms";
import { Help } from "../../../../common/types/commonTypes";
import { isString } from "../../../../common/types/typeGuard";
import MultipleViewText from "../../../shared/MultipleViewText";
import { useModal } from "../../hooks/modalHooks";
import styles from "../ModalContents.module.scss";

interface Props {
  help: Help;
}

const Membership: React.FC<Props> = ({ help }) => {
  const setDisplayMenu = useSetRecoilState(DisplayMenuState);
  const { userState } = useUserState();
  const { updateUserState } = useUserStateHooks();
  const [, setIsVisible] = useModal("infoHelp");
  const router = useRouter();

  const { helpTitleMV, helpTextMVArray } = help;
  const { withMembership } = userState;
  const {
    query: { ver, orgId, course, room },
  } = router;

  const register = (type: string) => {
    const currentUser = auth.currentUser;
    const provider = new GoogleAuthProvider();

    if (!currentUser) return;

    if (type === "google") {
      linkWithPopup(currentUser, provider)
        .then((result) => {
          toast.success(
            "アカウント登録に成功しました。今後クリア履歴がリセットされてしまった場合、同じメニュー内の「クリア履歴を復元する」をご利用ください。",
            { duration: 10000 }
          );

          const credential = GoogleAuthProvider.credentialFromResult(result);
          const user = result.user;

          console.log(credential);
          console.log(user);
          if (!isString(user.email))
            throw new Error("メールアドレスが見つかりません。");

          updateUserState("setMembership", user.email, "google");
        })
        .catch((error) => {
          // [ ] エラー種別でトースト用意
          console.log("error", error);

          //   const errorMessages = {
          //     'auth/user-not-found':
          //         'ユーザーが見つかりませんでした',
          //     'auth/requires-recent-login': '再ログインが必要です。',
          //     'auth/wrong-password': 'パスワードが違います。',
          //     'auth/weak-password': 'パスワードが弱いです。',
          //     'auth/email-already-in-use':
          //         '既に使われているメールアドレスです',
          //     'auth/operation-not-allowed':
          //         'メールアドレス/パスワード方式が有効化されていません。',
          //     'auth/invalid-email': ' メールアドレスの形式が正しくありません。',
          //     'auth/user-disabled': 'ユーザーが無効化されています。',
          // }
        });
    }
  };

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={helpTitleMV} />
        </div>
      </div>
      {helpTextMVArray.map((text, index) => {
        return (
          <div key={text?.kanji + index} className={styles.text}>
            <MultipleViewText text={text} />
          </div>
        );
      })}
      {withMembership && (
        <div className="mt-3 px-[1em] text-center text-xs text-red-500">
          <MultipleViewText text={divTexts.このアカウントはMV} />
        </div>
      )}
      {!withMembership && (
        <>
          <button
            type="button"
            onClick={() => register("google")}
            className={`heartbeat ${styles.btnGoogle}`}
            onTouchStart={() => {
              return {};
            }}
          >
            <IoLogoGoogle />
            <span className="ml-1">Googleアカウントで登録</span>
          </button>
          <button
            type="button"
            onClick={() => {
              setDisplayMenu("");
              setIsVisible(false);
              router.push({
                pathname: `/${ver}/${orgId}/${course}/auth/register`,
                query: { room },
              });
            }}
            className={`heartbeat ${styles.btnPass}`}
            onTouchStart={() => {
              return {};
            }}
          >
            <RiLockPasswordFill />
            <span className="ml-1">ID/パスワードで登録</span>
          </button>
        </>
      )}
    </div>
  );
};

export default Membership;
