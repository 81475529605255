import Image from "next/image";
import { useSettingState } from "../../common/context/settingState.context";

interface Props {
  url: string;
  width: number;
  height: number;
  alt: string;
  objectFit?: "cover" | "contain";
  loading?: "eager" | "lazy";
  withBlur?: boolean;
  furiganaSwitch?: boolean;
  extension?: string;
  className?: string;
}

const ImageComponent: React.FC<Props> = ({
  url,
  width,
  height,
  alt,
  objectFit = "contain",
  loading = "lazy",
  withBlur = true,
  furiganaSwitch = false,
  extension = "png",
  className = "",
}) => {
  const { withFurigana } = useSettingState();

  return (
    <Image
      src={`${url}.${extension}`}
      alt={alt}
      width={width}
      height={height}
      objectFit={objectFit}
      loading={loading}
      placeholder={withBlur ? "blur" : undefined}
      blurDataURL={
        withBlur
          ? `${url}${
              furiganaSwitch && withFurigana ? "_furi" : ""
            }.${extension}?auto=format,compress&q=1&blur=500&w=2`
          : undefined
      }
      className={className}
    />
  );
};

export default ImageComponent;
