import { UserGainedItem } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  userGainedItem: UserGainedItem;
}

const Default: React.FC<Props> = ({ userGainedItem }) => {
  const { itemNameMV, itemImageUrl, itemTextMVArray } = userGainedItem;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={itemNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap} ${styles.item}`}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={itemImageUrl}
              alt={itemNameMV?.kanji}
              width={400}
              height={400}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        {itemTextMVArray.map((itemTextMV, index) => {
          if (index === 0) return <div key={index} />;

          return (
            <div key={itemTextMV?.kanji + index}>
              <MultipleViewText text={itemTextMV} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Default;
