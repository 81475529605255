import { UserGainedTicket } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  userGainedTicket: UserGainedTicket;
}

const AnyTime: React.FC<Props> = ({ userGainedTicket }) => {
  const { ticketNameMV, ticketImageUrl, ticketTextMVArray } = userGainedTicket;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={ticketNameMV} />
        </div>
      </div>
      <div className={styles.imgWrap}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={ticketImageUrl}
              alt={ticketNameMV?.kanji}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        {ticketTextMVArray.map((itemTextMV, index) => {
          if (index === 0) return <div key={index} />;
          return (
            <div key={itemTextMV?.kanji + index}>
              <MultipleViewText text={itemTextMV} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnyTime;
