import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { useUserStateHooks } from "../../../common/hooks/userStateHooks";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import { isString } from "../../../common/types/typeGuard";
import ModalTemplate from "../../../templates/ModalTemplate";
import { useModal } from "../hooks/modalHooks";
import HowToPlay from "./infoOthersContents/HowToPlay";
import Precaution from "./infoOthersContents/Precaution";
import styles from "./ModalContents.module.scss";
import Survey from "./infoOthersContents/Survey";

const InfoOthers: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("infoOthers");
  const [modalData, setModalData] = useRecoilState(ModalDataState);
  const { updateUserState } = useUserStateHooks();
  const [setting, setSetting] = useState<{
    buttonFunction: VoidFunction;
    bgFunction: VoidFunction;
    buttonName: string;
    backButton: boolean;
  }>({
    buttonFunction: () => undefined,
    bgFunction: () => undefined,
    buttonName: "",
    backButton: false,
  });
  const router = useRouter();

  const {
    query: { ver, orgId, course, room },
  } = router;

  // リセットでトップページに行った時の対処
  useEffect(() => {
    if (!room) {
      setIsVisible(false);
      setModalData(null);
    }
  }, [room, setIsVisible, isVisible, setModalData]);

  const renderContent = useMemo(() => {
    if (!isString(modalData)) return null;

    if (modalData === "howToPlay") {
      setSetting({
        buttonFunction: () => {
          updateUserState("confirm", `${room}HowToPlay`);
          setModalData(null);
          setIsVisible(false);
        },
        bgFunction: () => {
          updateUserState("confirm", `${room}HowToPlay`);
          setModalData(null);
          setIsVisible(false);
        },
        buttonName: "閉じる",
        backButton: false,
      });

      return <HowToPlay />;
    }

    if (modalData === "precaution") {
      setSetting({
        buttonFunction: () => {
          updateUserState("confirm", "precaution");
          setModalData(null);
          setIsVisible(false);
        },
        bgFunction: () => {
          return;
        },
        buttonName: "同意する",
        backButton: true,
      });

      return <Precaution />;
    }
  }, [modalData, room, setIsVisible, setModalData, updateUserState]);

  // survey（アンケート）の時は専用コンポーネントに移動
  if (modalData === "survey") return <Survey />;

  // [ ] modalごとの閉じるボタンの相談
  return (
    <>
      {isVisible && (
        <ModalTemplate bgCloseFunction={setting.bgFunction}>
          <div className={styles.modalBody}>
            {renderContent}
            <div className={styles.btnsWrap}>
              <div className={styles.btnModalAgree}>
                <button type="button" onClick={() => setting.buttonFunction()}>
                  {setting.buttonName}
                </button>
              </div>
              {/* 利用規約など同意しないと進めない内容の時はトップページへの戻るボタンを表示 */}
              {setting.backButton && (
                <div className={styles.btnBack}>
                  <button
                    type="button"
                    onClick={() => {
                      setModalData(null);
                      setIsVisible(false);
                      router.push(`/${ver}/${orgId}/${course}`);
                    }}
                  >
                    もどる
                  </button>
                </div>
              )}
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default InfoOthers;
