import { Help } from "../types/commonTypes";

export const helpData: Help[] = [
  {
    type: "questHint",
    priority: 1000,
    withPing: "",
    helpTitleMV: {
      kanji: "ヒントが欲しい",
      furigana: 'ヒントが<ruby data-ruby="ほ">欲</ruby>しい',
    },
    helpTextMVArray: [{ kanji: "", furigana: "" }],
    helpDataArray: [],
  },
  {
    type: "membership",
    priority: 900,
    withPing: "membership",
    helpTitleMV: {
      kanji: "クリア履歴を保存する",
      furigana: "",
    },
    helpTextMVArray: [
      {
        kanji:
          "クリア履歴はブラウザに紐付いて保存されていますが、端末の種類や設定によっては次回このページを開いた際に履歴がリセットされている可能性があります。",
        furigana: "",
      },
      {
        kanji:
          "万が一リセットされた時に復旧できるよう、以下のいずれかの方法でアカウント登録しておくことをオススメします。アカウント登録されていない場合、復旧することはできませんので予めご了承ください。",
        furigana: "",
      },
      {
        kanji:
          "ここでご登録いただくメールアドレスなどの情報を他の目的に利用することはありません。",
        furigana: "",
      },
    ],
    helpDataArray: [],
  },
  {
    type: "restoreMembership",
    priority: 800,
    withPing: "",
    helpTitleMV: {
      kanji: "クリア履歴を復元する",
      furigana: "",
    },
    helpTextMVArray: [
      {
        kanji:
          "同じヘルプ内の「クリア履歴を保存する」にて、いずれかの方法でアカウント登録をしている場合、履歴がリセットされていても以下から復元ができます。",
        furigana: "",
      },
      {
        kanji: "アカウント登録した時と同じものを選んで進めてください。",
        furigana: "",
      },
      {
        kanji: "アカウント登録されていない場合、復元することはできません。",
        furigana: "",
      },
    ],
    helpDataArray: [],
  },
  // {
  //   type: "gpsHeading",
  //   priority: 700,
  //   withPing: "",
  //   helpTitleMV: {
  //     kanji: "レーダーが「起動中」から変わらない",
  //     furigana: "",
  //   },
  //   helpTextMVArray: [
  //     {
  //       kanji: "何らかの原因によりGPS機能をオンにできておりません。",
  //       furigana: "",
  //     },
  //     {
  //       kanji:
  //         "対策ボタンをタップしても起動中から変わらない場合、ブラウザアプリを変える・キャッシュをクリアする・アプリを入れ直すことで起動できるようになる場合があります（ここまでのクリア履歴は消えてしまいます。必要に応じてヘルプ内の「クリア履歴を保存する」をご利用ください）。",
  //       furigana: "",
  //     },
  //   ],
  //   helpDataArray: [],
  // },
  {
    type: "default",
    priority: 600,
    withPing: "",
    helpTitleMV: {
      kanji: "レーダーが「探索中」から変わらない",
      furigana:
        'レーダーが「<ruby data-ruby="たんさく">探索</ruby><ruby data-ruby="ちゅう">中</ruby>」から<ruby data-ruby="か">変</ruby>わらない',
    },
    helpTextMVArray: [
      {
        kanji:
          "目的地ごとに設定されたGPSの範囲内に入ると、ボタンが自動的に「発見」に変わりタップできるようになります（目的地によって反応する範囲は異なります）。",
        furigana: "",
      },
    ],
    helpDataArray: [],
  },
  // {
  //   type: "default",
  //   priority: 400,
  //   withPing: "",
  //   helpTitleMV: {
  //     kanji: "レーダーで端末の向きが表示されない",
  //     furigana:
  //       'レーダーで<ruby data-ruby="たんまつ">端末</ruby>の<ruby data-ruby="む">向</ruby>きが<ruby data-ruby="ひょうじ">表示</ruby>されない',
  //   },
  //   helpTextMVArray: [
  //     {
  //       kanji:
  //         "許可を求めるポップアップで拒否をタップしている可能性があります。\n起動中のブラウザを一度閉じ、バックグラウンドからも終了させた上で再度起動すると再びポップアップが表示されます。\nバックグラウンドからの終了方法はOSにより異なるため「ご使用の端末名 アプリ バックグラウンド 終了」などで検索してください。",
  //       furigana: "",
  //     },
  //   ],
  //   helpDataArray: [],
  // },
  {
    type: "precaution",
    priority: 200,
    withPing: "",
    helpTitleMV: {
      kanji: "利用規約・注意事項",
      furigana:
        '<ruby data-ruby="ちゅういじこう">注意事項</ruby>・<ruby data-ruby="りようきやく">利用規約</ruby>',
    },
    helpTextMVArray: [
      {
        kanji: "",
        furigana: "",
      },
    ],
    helpDataArray: [],
  },
  {
    type: "privacyPolicy",
    priority: 100,
    withPing: "",
    helpTitleMV: {
      kanji: "プライバシーポリシー",
      furigana: "",
    },
    helpTextMVArray: [
      {
        kanji: "",
        furigana: "",
      },
    ],
    helpDataArray: [],
  },
  {
    type: "setting",
    priority: 0,
    withPing: "",
    helpTitleMV: {
      kanji: "設定",
      furigana: '<ruby data-ruby="せってい">設定</ruby>',
    },
    helpTextMVArray: [{ kanji: "", furigana: "" }],
    helpDataArray: [],
  },
];
