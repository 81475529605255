import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { useSettingState } from "../../../../common/context/settingState.context";
import { useUserState } from "../../../../common/context/userState.context";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import styles from "../ModalContents.module.scss";
import { DisplayMenuState } from "../../../../common/recoil/menuAtoms";
import { isSettingState } from "../../../../common/types/typeGuard";
import axios from "axios";

interface SetIdPasswordResponse {
  message: string;
}

const Setting: React.FC = () => {
  const { updateUserState } = useUserStateHooks();
  const setDisplayMenu = useSetRecoilState(DisplayMenuState);
  const { userState } = useUserState();
  const { settingState } = useSettingState();
  const router = useRouter();

  if (!isSettingState(settingState)) return null;

  const { uid } = userState;
  const {
    query: { ver, orgId, course },
  } = router;
  const { withSampleMode, withTestMode } = settingState;

  const resetUserState = () => {
    toast.success("リセットしました。");
    setDisplayMenu("");
    updateUserState("reset", "none");
    router.push(`/${ver}/${orgId}/${course}`);
  };

  const deleteUsers = async () => {
    const data = {};

    const {
      data: { message },
    } = await axios.post<SetIdPasswordResponse>("/api/auth/deleteUser", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    toast.success(message);
  };

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.text}>uid:{uid}</div>
      {(withSampleMode || withTestMode) && (
        <div className={styles.text}>
          <div>
            {withSampleMode && (
              <p>
                サンプルモードのため、これまでのクリア履歴をリセットできます。
              </p>
            )}
          </div>
          <div>
            {withTestMode && (
              <p>
                テストモードのため、これまでのクリア履歴をリセットできます。
              </p>
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={resetUserState}
              className="m-1 rounded-md bg-amber-500 p-1"
            >
              クリア履歴をリセット
            </button>
          </div>
          {/* <div>
            <button
              type="button"
              className="m-1 rounded-md bg-amber-500 p-1"
              onClick={deleteUsers}
            >
              全ユーザー削除
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Setting;
