import { TextMV } from "../types/commonTypes";

export const buttonTexts: { [key: string]: TextMV } = {
  挑戦するMV: {
    kanji: "挑戦する",
    furigana: '<ruby data-ruby="ちょうせん">挑戦</ruby>する',
  },
  クリア済みMV: {
    kanji: "クリア済み",
    furigana: 'クリア<ruby data-ruby="ず">済</ruby>み',
  },
  送信MV: {
    kanji: "送信",
    furigana: '<ruby data-ruby="そうしん">送信</ruby>',
  },
  さらなるヒントはこちらMV: {
    kanji: "さらなるヒントはこちら",
    furigana: "さらなるヒントはこちら",
  },
  使用するMV: {
    kanji: "使用する",
    furigana: '<ruby data-ruby="しよう">使用</ruby>する',
  },
  使用済みMV: {
    kanji: "使用済み",
    furigana:
      '<ruby data-ruby="しよう">使用</ruby><ruby data-ruby="ず">済</ruby>み',
  },
  進めるMV: {
    kanji: "進める",
    furigana: '<ruby data-ruby="すす">進</ruby>める',
  },
  閉じるMV: {
    kanji: "閉じる",
    furigana: '<ruby data-ruby="と">閉</ruby>じる',
  },
  次へMV: {
    kanji: "次へ",
    furigana: '<ruby data-ruby="つぎ">次</ruby>へ',
  },
  答えるMV: {
    kanji: "答える",
    furigana: '<ruby data-ruby="こた">答</ruby>える',
  },
  GoogleアカウントでMV: {
    kanji: "Googleアカウントで復元",
    furigana: "",
  },
  登録MV: {
    kanji: "登録",
    furigana: '<ruby data-ruby="とうろく">登録</ruby>',
  },
};
