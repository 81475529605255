import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleTexts } from "../../../common/const/titleTexts";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import { isItem } from "../../../common/types/typeGuard";
import ModalTemplate from "../../../templates/ModalTemplate";
import ImageComponent from "../../shared/ImageComponent";
import MultipleViewText from "../../shared/MultipleViewText";
import { useModal } from "../hooks/modalHooks";
import styles from "./ModalContents.module.scss";

const GainItem: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("gainItem");
  const setModalData = useSetRecoilState(ModalDataState);

  return (
    <>
      {isVisible && (
        <ModalTemplate
          bgCloseFunction={() => {
            setModalData(null);
            setIsVisible(false);
          }}
        >
          <div className={styles.modalBody}>
            <ModalGainItem />
          </div>
          <div className={styles.btnModalClose}>
            <button
              type="button"
              onClick={() => {
                setModalData(null);
                setIsVisible(false);
              }}
            >
              <ImageComponent
                url="/images/btn/default/btn_close01"
                alt="閉じるボタン"
                width={36}
                height={36}
                objectFit="cover"
                withBlur={false}
              />
            </button>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default GainItem;

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const ModalGainItem: React.FC = () => {
  const modalData = useRecoilValue(ModalDataState);

  // modalDataがunionなので型判定
  if (!isItem(modalData)) return null;

  const { itemNameMV, itemImageUrl, itemTextMVArray } = modalData;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.message}>
        <MultipleViewText text={titleTexts?.アイテムを手に入れたMV} />
      </div>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={itemNameMV} />
        </div>
      </div>
      <div className={styles.imgWrap}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={itemImageUrl}
              alt={itemNameMV?.kanji}
              width={200}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <MultipleViewText text={itemTextMVArray[0]} />
      </div>
    </div>
  );
};
