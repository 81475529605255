import { HowToPlay } from "../types/modalTypes";

export const howToPlayData: HowToPlay[] = [
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    index: "howToPlay1",
    type: "default",
    textMVArray: [
      {
        kanji:
          "まずは駅前通りの5スポットから<span class='font-bold text-red-500'>最低3カ所</span>に挑戦し、秘宝の手がかりを集めよう！どこから回っても大丈夫だよ！",
        furigana: "",
      },
      {
        kanji:
          "3カ所で謎を解いてアイテムを集めると、最後の謎が現れるよ！時間制限はないのでゆっくり挑戦してね。",
        furigana: "",
      },
      {
        kanji: "困った時はメニューの中にあるヘルプを確認してね！",
        furigana: "",
      },
      {
        kanji:
          "クリア履歴は保存することができるよ。これも詳しくはメニューの中のヘルプを見てね。",
        furigana: "",
      },
    ],
  },
];
