import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import { isUserGainedTicket } from "../../../common/types/typeGuard";
import ModalTemplate from "../../../templates/ModalTemplate";
import ImageComponent from "../../shared/ImageComponent";
import { useModal } from "../hooks/modalHooks";
import AnyTime from "./infoTicketContents/AnyTime";
import OnlyOnce from "./infoTicketContents/OnlyOnce";

import styles from "./ModalContents.module.scss";

const InfoTicket: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("infoTicket");
  const [modalData, setModalData] = useRecoilState(ModalDataState);

  const renderContent = useMemo(() => {
    // modalDataがunionなので型判定
    if (!isUserGainedTicket(modalData)) return null;

    const { type } = modalData;

    if (type === "anytime") return <AnyTime userGainedTicket={modalData} />;

    if (type === "onlyOnce") return <OnlyOnce userGainedTicket={modalData} />;
  }, [modalData]);

  return (
    <>
      {isVisible && (
        <ModalTemplate
          bgCloseFunction={() => {
            setModalData(null);
            setIsVisible(false);
          }}
        >
          <div className={styles.modalBody}>{renderContent}</div>
          <div className={styles.btnModalClose}>
            <button
              type="button"
              onClick={() => {
                setModalData(null);
                setIsVisible(false);
              }}
            >
              <ImageComponent
                url="/images/btn/default/btn_close01"
                alt="閉じるボタン"
                width={36}
                height={36}
                objectFit="cover"
                withBlur={false}
              />
            </button>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default InfoTicket;
