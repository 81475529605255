import { Survey } from "../types/modalTypes";

export const surveyData: Survey[] = [
  {
    ver: "v2",
    orgId: "tom-sawyer",
    course: "odori-sample",
    room: "room1",
    index: "survey1",
    type: "default",
    surveyNameMV: {
      kanji: "開始前アンケート",
      furigana: "",
    },
    surveyTextMVArray: [
      {
        kanji: "以下のアンケート（全3問）にご協力をお願いいたします。",
        furigana: "",
      },
    ],
    surveyArray: [
      {
        model: "radio",
        defaultValue: "",
        nameMV: {
          kanji: "性別をお選びください。",
          furigana: "",
        },
        inputType: "none",
        choiceMVArray: [
          {
            kanji: "男性",
            furigana: '<ruby data-ruby="だんせい">男性</ruby>',
          },
          {
            kanji: "女性",
            furigana: '<ruby data-ruby="じょせい">女性</ruby>',
          },
          { kanji: "その他", furigana: 'その<ruby data-ruby="た">他</ruby>' },
          {
            kanji: "回答しない",
            furigana: '<ruby data-ruby="かいとう">回答</ruby>しない',
          },
        ],
        validation: {
          required: {
            value: true,
            message: "この項目は必須です。",
          },
        },
      },
      {
        model: "radio",
        defaultValue: "",
        nameMV: {
          kanji: "年代をお選びください。",
          furigana: "",
        },
        inputType: "none",
        choiceMVArray: [
          {
            kanji: "10歳未満",
            furigana:
              '10<ruby data-ruby="さい">歳</ruby><ruby data-ruby="みまん">未満</ruby>',
          },
          { kanji: "10代", furigana: '10<ruby data-ruby="だい">代</ruby>' },
          { kanji: "20代", furigana: '20<ruby data-ruby="だい">代</ruby>' },
          { kanji: "30代", furigana: '30<ruby data-ruby="だい">代</ruby>' },
          { kanji: "40代", furigana: '40<ruby data-ruby="だい">代</ruby>' },
          { kanji: "50代", furigana: '50<ruby data-ruby="だい">代</ruby>' },
          { kanji: "60代", furigana: '60<ruby data-ruby="だい">代</ruby>' },
          { kanji: "70代", furigana: '70<ruby data-ruby="だい">代</ruby>' },
          {
            kanji: "80歳以上",
            furigana:
              '80<ruby data-ruby="さい">歳</ruby><ruby data-ruby="いじょう">以上</ruby>',
          },
        ],
        validation: {
          required: {
            value: true,
            message: "この項目は必須です。",
          },
        },
      },
      {
        model: "radio",
        defaultValue: "",
        nameMV: {
          kanji: "居住地をお選びください。",
          furigana: "",
        },
        inputType: "none",
        choiceMVArray: [
          {
            kanji: "道央",
            furigana:
              '<ruby data-ruby="みち">道</ruby><ruby data-ruby="ひさし">央</ruby>',
          },
          {
            kanji: "道北",
            furigana: '<ruby data-ruby="みちきた">道北</ruby>',
          },
          {
            kanji: "道東",
            furigana: '<ruby data-ruby="どうとう">道東</ruby>',
          },
          {
            kanji: "道南",
            furigana:
              '<ruby data-ruby="みち">道</ruby><ruby data-ruby="みなみ">南</ruby>',
          },
          {
            kanji: "青森県",
            furigana:
              '<ruby data-ruby="あおもり">青森</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "岩手県",
            furigana:
              '<ruby data-ruby="いわて">岩手</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "宮城県",
            furigana:
              '<ruby data-ruby="みやぎ">宮城</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "秋田県",
            furigana:
              '<ruby data-ruby="あきた">秋田</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "山形県",
            furigana:
              '<ruby data-ruby="やまがた">山形</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "福島県",
            furigana:
              '<ruby data-ruby="ふくしま">福島</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "茨城県",
            furigana:
              '<ruby data-ruby="いばらき">茨城</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "栃木県",
            furigana:
              '<ruby data-ruby="とちぎ">栃木</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "群馬県",
            furigana:
              '<ruby data-ruby="ぐんま">群馬</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "埼玉県",
            furigana:
              '<ruby data-ruby="さいたま">埼玉</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "千葉県",
            furigana:
              '<ruby data-ruby="ちば">千葉</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "東京都",
            furigana:
              '<ruby data-ruby="とうきょう">東京</ruby><ruby data-ruby="と">都</ruby>',
          },
          {
            kanji: "神奈川県",
            furigana:
              '<ruby data-ruby="かながわ">神奈川</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "新潟県",
            furigana:
              '<ruby data-ruby="にいがた">新潟</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "富山県",
            furigana:
              '<ruby data-ruby="とやま">富山</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "石川県",
            furigana:
              '<ruby data-ruby="いしかわ">石川</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "福井県",
            furigana:
              '<ruby data-ruby="ふくい">福井</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "山梨県",
            furigana:
              '<ruby data-ruby="やまなし">山梨</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "長野県",
            furigana:
              '<ruby data-ruby="ながの">長野</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "岐阜県",
            furigana:
              '<ruby data-ruby="ぎふ">岐阜</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "静岡県",
            furigana:
              '<ruby data-ruby="しずおか">静岡</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "愛知県",
            furigana:
              '<ruby data-ruby="あいち">愛知</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "三重県",
            furigana:
              '<ruby data-ruby="みえ">三重</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "滋賀県",
            furigana:
              '<ruby data-ruby="しが">滋賀</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "京都府",
            furigana:
              '<ruby data-ruby="きょうと">京都</ruby><ruby data-ruby="ふ">府</ruby>',
          },
          {
            kanji: "大阪府",
            furigana:
              '<ruby data-ruby="おおさか">大阪</ruby><ruby data-ruby="ふ">府</ruby>',
          },
          {
            kanji: "兵庫県",
            furigana:
              '<ruby data-ruby="ひょうご">兵庫</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "奈良県",
            furigana:
              '<ruby data-ruby="なら">奈良</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "和歌山県",
            furigana:
              '<ruby data-ruby="わかやま">和歌山</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "鳥取県",
            furigana:
              '<ruby data-ruby="とっとり">鳥取</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "島根県",
            furigana:
              '<ruby data-ruby="しまね">島根</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "岡山県",
            furigana:
              '<ruby data-ruby="おかやま">岡山</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "広島県",
            furigana:
              '<ruby data-ruby="ひろしま">広島</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "山口県",
            furigana:
              '<ruby data-ruby="やまぐち">山口</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "徳島県",
            furigana:
              '<ruby data-ruby="とくしま">徳島</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "香川県",
            furigana:
              '<ruby data-ruby="かがわ">香川</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "愛媛県",
            furigana:
              '<ruby data-ruby="えひめ">愛媛</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "高知県",
            furigana:
              '<ruby data-ruby="こうち">高知</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "福岡県",
            furigana:
              '<ruby data-ruby="ふくおか">福岡</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "佐賀県",
            furigana:
              '<ruby data-ruby="さが">佐賀</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "熊本県",
            furigana:
              '<ruby data-ruby="くまもと">熊本</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "大分県",
            furigana:
              '<ruby data-ruby="おおいた">大分</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "宮崎県",
            furigana:
              '<ruby data-ruby="みやざき">宮崎</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "鹿児島県",
            furigana:
              '<ruby data-ruby="かごしま">鹿児島</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "長崎県",
            furigana:
              '<ruby data-ruby="ながさき">長崎</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "沖縄県",
            furigana:
              '<ruby data-ruby="おきなわ">沖縄</ruby><ruby data-ruby="けん">県</ruby>',
          },
          {
            kanji: "海外",
            furigana: '<ruby data-ruby="かいがい">海外</ruby>',
          },
        ],
        validation: {
          required: {
            value: true,
            message: "この項目は必須です。",
          },
        },
      },
    ],
  },
];
